import React from 'react'

function Page404() {
    return (
        <div>
            404 Not Found
        </div>
    )
}

export default Page404
